import { YoutubeComponent as YoutubeModel } from "movestic-blocks/components/__generated__/graphql";

export const Youtube = (props: YoutubeModel) => (
    <div>
        <iframe
            className="rounded-lg"
            style={{ width: "100%", aspectRatio: "16/9" }}
            src={"https://www.youtube.com/embed/" + props.url}
            frameBorder="0"
        ></iframe>
    </div>
);
