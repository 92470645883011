"use client";
import MenuItemLink from "movestic-ui/types/menu/MenuItemLink";
import Link from "next/link";
import React from "react";

export const Breadcrumb: React.FunctionComponent<{
    navigation: MenuItemLink[];
    className?: string;
}> = ({ navigation, className }) => {
    return (
        <div className={`flex gap-2 ${className}`}>
            <style jsx>
                {`
                    .last-breadcrumb {
                        display: block;
                    }

                    @media (max-width: 720px) {
                        .last-breadcrumb {
                            display: none;
                        }
                    }
                `}
            </style>
            {navigation?.map((nav, index) =>
                index !== navigation.length - 1 ? (
                    <div key={nav.text} className="flex gap-2">
                        <Link href={nav.url} key={index} className="text-cherryDark hover:text-cherryRed">
                            {nav.text}
                        </Link>

                        {index !== navigation.length - 1 && <div>›</div>}
                    </div>
                ) : (
                    <div key={nav.text} className="last-breadcrumb">
                        {nav.text}
                    </div>
                ),
            )}
        </div>
    );
};
