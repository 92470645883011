"use client";
import {
    ListComponent as ListModel,
    ListitemComponent as ListitemModel,
} from "movestic-blocks/components/__generated__/graphql";
import { Chevron } from "movestic-ui/tailwind/icons/Chevron";
import { H3 } from "movestic-ui/tailwind/Texts";
import { useState } from "react";
import { RichtextContent } from "../RichtextBlockRenderer";

interface ExtendedListitemModel extends ListitemModel {
    className?: string;
}

const ListItem = ({ text, className }: ExtendedListitemModel) => {
    return <li className={className}>{text}</li>;
};

export const ListBlock = ({ listIcon, listItems }: ListModel) => {
    let listItemStyle = "";
    switch (listIcon) {
        case "blackBullet":
            listItemStyle = "list-none pl-8 pb-2 bg-[url('/static/images/icon-bullet-black.svg')] bg-no-repeat";
            break;
        case "coloredCheck":
            listItemStyle = "list-none pl-8 pb-2 bg-[url('/static/images/icon-check.svg')] bg-no-repeat "; //[&>li_p]:inline
            break;
        default:
            listItemStyle = "list-none pl-8 pb-2 bg-[url('/static/images/icon-bullet.svg')] bg-no-repeat";
            break;
    }

    return (
        <ul>{listItems?.map((item, index) => <ListItem key={index} text={item.text} className={listItemStyle} />)}</ul>
    );
};
