import * as React from "react";
import { Asset, ShortcutlistComponent as ShortcutListModel } from "movestic-blocks/components/__generated__/graphql";
import { ShortcutListHigh } from "./ShortcutList/ShortcutlistHigh";
import { ShortcutListSimple } from "./ShortcutList/ShortcutlistSimple";
import { ShortcutListLow } from "./ShortcutList/ShortcutlistLow";

export const ShortcutList = (props: ShortcutListModel) => {
    switch (props.type?.toLowerCase()) {
        case "type1":
        case "high":
            return <ShortcutListHigh props={props} />;
        case "type3":
        case "low":
            return <ShortcutListLow props={props} />;
        default:
            return <ShortcutListSimple props={props} />;
    }
};

export type ShortcutModel = {
    linkUrl: string;
    linkTarget?: string;
    title: string;
    text?: string;
    label?: string;
    icon: Asset;
    children?: React.ReactNode;
    cols?: number;
    index?: number;
    iconPosition?: string;
};
