"use client";
import * as React from "react";
import {
    NewslistComponent as NewslistModel,
    NewsListQueryQuery,
} from "movestic-blocks/components/__generated__/graphql";
import { useQuery } from "react-query";
import { H2, H3, H4 } from "movestic-ui/tailwind/Texts";
import Link from "next/link";
import { getLink, getNews } from "../../utils/cms/storyblokHelpers";
import { PrimaryLink } from "movestic-ui/tailwind/Buttons";
import { ArrowRight } from "movestic-ui/tailwind/icons/ArrowRight";
import { NewsListItem } from "../component/NewsListItem";

export const LatestNewsBlock: React.FunctionComponent<NewslistModel> = ({
    totalNews,
    tags,
    heading,
    shadow,
    link,
    linkText,
    type,
    category,
}) => {
    const news = useQuery<NewsListQueryQuery>("news-list", () =>
        fetch(`/api/cms/news?page=1&per_page=${totalNews}&tag=${tags}&category=${category.join(",")}`).then((res) =>
            res.json(),
        ),
    );

    const newsItems = news.data?.NewsitemItems?.items;
    const { linkUrl, linkTarget } = getLink(link);

    if (news.isLoading) {
        return <div>Laddar...</div>;
    }

    if (news.isError) {
        return <div>Kunde inte ladda nyheter. Försök igen senare.</div>;
    }

    const newslinkPrefix = tags === "partner" ? "partner" : "";

    return (
        <div className={` ${shadow ? "shadow p-6 rounded-lg" : ""}`}>
            {heading ? (
                type === "high" ? (
                    <H2 className="pl-4">{heading}</H2>
                ) : (
                    <H4 className="pb-4 ">{heading}</H4>
                )
            ) : null}
            <div className={type === "high" ? "grid grid-cols-1 gap-6 p-4 md:grid-cols-2" : ""}>
                {newsItems?.map((item, index) => {
                    const dateString = new Date(item.content.date).toLocaleDateString();
                    return type === "high" ? (
                        <NewsListItem
                            item={item.content}
                            name={item.content.heading}
                            url={`${newslinkPrefix}/${item.full_slug}`}
                            key={`newsitem_${index}`}
                        />
                    ) : (
                        <div
                            className={`py-3 border-b border-lightGray ${index === 0 ? "border-t" : ""}`}
                            key={`newitem_${index}`}
                        >
                            <div className="mb-1 text-gray text-small">{dateString}</div>
                            <div>
                                <Link
                                    className="no-underline hover:underline"
                                    href={`${newslinkPrefix}/${item.full_slug}`}
                                >
                                    {item.content.heading}
                                </Link>
                            </div>
                        </div>
                    );
                })}
            </div>
            {linkUrl && (
                <div className={`${type === "high" ? "w-full flex justify-end pr-4" : ""}`}>
                    <PrimaryLink href={linkUrl} target={linkTarget} className="flex flex-row gap-2 pt-6">
                        {linkText}
                        <ArrowRight />
                    </PrimaryLink>
                </div>
            )}
            {(!newsItems || newsItems.length === 0) && <div>Inga nyheter hittades</div>}
        </div>
    );
};
