export const QuestionMark = ({
    size = "56",
    color = "#D5004B",
}: {
    size?: string;
    color?: string;
    style?: React.CSSProperties;
}) => (
    <svg width={size} height={size} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="question">
            <path
                id="Vector"
                d="M28.0009 20.2222C27.046 20.2222 26.1301 20.5734 25.4549 21.1985C24.7797 21.8237 24.4004 22.6715 24.4004 23.5556V23.7933C24.4004 24.088 24.2739 24.3706 24.0488 24.579C23.8238 24.7874 23.5185 24.9044 23.2002 24.9044C22.8819 24.9044 22.5766 24.7874 22.3515 24.579C22.1264 24.3706 22 24.088 22 23.7933V23.5556C22 22.0821 22.6322 20.6691 23.7576 19.6272C24.883 18.5853 26.4093 18 28.0009 18H28.2793C29.4481 18.0003 30.5887 18.332 31.5478 18.9503C32.5069 19.5687 33.2385 20.4441 33.6442 21.4588C34.0499 22.4735 34.1102 23.5789 33.8171 24.6264C33.524 25.6738 32.8915 26.613 32.0047 27.3178L30.154 28.7867C29.8556 29.0241 29.6159 29.3183 29.4515 29.6493C29.287 29.9802 29.2016 30.3401 29.2011 30.7044V31.8889C29.2011 32.1836 29.0746 32.4662 28.8495 32.6746C28.6245 32.8829 28.3192 33 28.0009 33C27.6826 33 27.3773 32.8829 27.1522 32.6746C26.9271 32.4662 26.8007 32.1836 26.8007 31.8889V30.7044C26.8007 29.3178 27.456 28.0022 28.5914 27.1L30.4396 25.6333C30.9551 25.2245 31.3229 24.6793 31.4935 24.0711C31.6642 23.4629 31.6295 22.8209 31.3942 22.2315C31.1589 21.6421 30.7342 21.1336 30.1773 20.7744C29.6205 20.4151 28.9581 20.2224 28.2793 20.2222H28.0009ZM28.0009 38C28.4783 38 28.9362 37.8244 29.2739 37.5118C29.6115 37.1993 29.8011 36.7754 29.8011 36.3333C29.8011 35.8913 29.6115 35.4674 29.2739 35.1548C28.9362 34.8423 28.4783 34.6667 28.0009 34.6667C27.5234 34.6667 27.0655 34.8423 26.7279 35.1548C26.3903 35.4674 26.2006 35.8913 26.2006 36.3333C26.2006 36.7754 26.3903 37.1993 26.7279 37.5118C27.0655 37.8244 27.5234 38 28.0009 38Z"
                fill={color}
            />
            <path
                id="Vector_2"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44.2968 44.2969C39.9438 48.6494 34.1563 51.047 28 51.047C21.8439 51.047 16.0564 48.6498 11.7034 44.2969C7.3505 39.9437 4.953 34.1562 4.953 28C4.953 21.8437 7.35031 16.0562 11.7034 11.7033C16.0564 7.35044 21.8439 4.95294 28 4.95294C34.1563 4.95294 39.9438 7.3502 44.2968 11.7033C48.6498 16.0562 51.0472 21.8437 51.0472 28C51.0472 34.1562 48.65 39.9437 44.2968 44.2969ZM45.678 10.3225C40.9557 5.60044 34.678 3 28 3C21.3221 3 15.0442 5.60044 10.3225 10.3225C5.60043 15.0442 3 21.3226 3 28C3 34.6775 5.60043 40.9557 10.3225 45.6775C15.0442 50.3994 21.3225 53 28 53C34.6776 53 40.9557 50.3994 45.678 45.6775C50.3994 40.9557 53 34.6775 53 28C53 21.3226 50.3994 15.0442 45.678 10.3225Z"
                fill={color}
            />
        </g>
    </svg>
);
