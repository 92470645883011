"use client";
import { FooterComponent } from "../components/__generated__/graphql";
import { footerContent, RichtextContent } from "./RichtextBlockRenderer";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const Footer = ({
    footer,
    authenticatedUser,
    funds,
}: {
    footer: FooterComponent;
    authenticatedUser?: { name: string; impersonator?: string };
    funds?: boolean;
}) => {
    return (
        <footer className="mt-auto overflow-hidden text-white bg-cherryDark">
            <style jsx>
                {`
                    .movestic-background:before {
                        content: "";
                        display: inline-block;
                        width: 604px;
                        height: 540px;
                        background-image: url("https://a.storyblok.com/f/272467/604x540/412454f2fa/m-logo-background.svg");
                        background-position: right center;
                        position: absolute;
                        right: -10rem;
                        top: -2rem;
                        opacity: 0.4;
                        pointer-events: none;
                    }

                    @media (max-width: 768px) {
                        .movestic-background:before {
                            content: none;
                        }
                    }
                `}
            </style>
            {footer ? (
                <div
                    className={`movestic-background relative container grid grid-cols-1 gap-8 px-4 md:px-8 py-16 md:grid-cols-4 bg-no-repeat bg-left-bottom md:bg-right`}
                >
                    <div className="md:col-span-4">
                        {funds ? (
                            <img
                                src={"https://a.storyblok.com/f/272467/124x40/fee2d52c21/movesticfonder-logo-white.svg"}
                                alt="Movestic fonder logo"
                            />
                        ) : (
                            <img
                                src={"https://a.storyblok.com/f/272467/140x40/c8cd00a9ca/logo-white.svg"}
                                alt="Movestic logo"
                            />
                        )}
                    </div>
                    <div className="">
                        <RichtextContent richContent={footer?.content1} resolvers={footerContent} />
                    </div>
                    <div className="">
                        <RichtextContent richContent={footer?.content2} resolvers={footerContent} />
                    </div>
                    <div className="">
                        {authenticatedUser && (
                            <div>
                                <p>Inloggad: {authenticatedUser.name}</p>
                                {authenticatedUser.impersonator && (
                                    <p>Impersonator: {authenticatedUser.impersonator}</p>
                                )}
                            </div>
                        )}
                        <RichtextContent richContent={footer?.content3} resolvers={footerContent} />
                    </div>
                    <div className="md:col-span-4">
                        <RichtextContent richContent={footer?.contentBottom} resolvers={footerContent} />
                    </div>
                    <div className="md:col-span-4 text-small">© Movestic {new Date().getFullYear()}</div>
                </div>
            ) : (
                <Skeleton height={450} baseColor="bg-cherryDark" />
            )}
        </footer>
    );
};
