"use client";

import {
    AccordionitemComponent as AccordionitemModel,
    AccordionlistComponent as AccordionlistModel,
} from "movestic-blocks/components/__generated__/graphql";
import { useState } from "react";
import { Chevron } from "movestic-ui/tailwind/icons/Chevron";
import { RichtextContent } from "../RichtextBlockRenderer";

const AccordionItem = ({ title, text, index, openIndex, setOpenIndex, numbred }) => {
    const isOpen = index === openIndex;
    const handleClick = () => {
        setOpenIndex(isOpen ? null : index);
    };

    return (
        <div className={`flex flex-col rounded-lg shadow mb-6 last:mb-0 text-lightBlack`}>
            <button
                className={`cursor-pointer ${isOpen ? "bg-brightGray rounded-t-lg" : "bg-white rounded-lg"} `}
                onClick={handleClick}
                tabIndex={0}
            >
                <div className={`flex p-6 justify-between`}>
                    <div className="font-bold text-left">
                        {numbred ? <span className="font-bold text-cherryRed">{index + 1}. </span> : ""}
                        {title}
                    </div>
                    <div className={`transition ${isOpen ? "rotate-180" : ""}`}>
                        <Chevron />
                    </div>
                </div>
            </button>
            <div
                className={`bg-white overflow-hidden transition-opacity rounded-b-lg transition-max-height ${isOpen ? "opacity-100 max-h-[3500px]" : "opacity-0 max-h-0"}`}
            >
                <div className={`p-6`}>
                    <RichtextContent richContent={text} />
                </div>
            </div>
        </div>
    );
};

export const AccordionList = (props: AccordionlistModel) => {
    const [openIndex, setOpenIndex] = useState(null);
    return (
        <div>
            {props.accordionItems?.map((item: AccordionitemModel, index: number) => (
                <AccordionItem
                    key={index}
                    title={item.title}
                    text={item.text}
                    index={index}
                    openIndex={openIndex}
                    setOpenIndex={setOpenIndex}
                    numbred={props.numbred}
                />
            ))}
        </div>
    );
};
