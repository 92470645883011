import * as React from "react";
import { ModuleComponent as ModuleProps } from "movestic-blocks/components/__generated__/graphql";
import { MonthSavingsCalculation } from "movestic-ui/tailwind/MonthSavingsCalculation";
import { SalaryTaxExchange } from "movestic-ui/tailwind/SalaryTaxExchange";
import { CookieConsentChangeButton } from "movestic-blocks/tailwind/component/cookieConsent/CookieConsent";

export const Module: React.FunctionComponent<ModuleProps> = ({ module }) => {
    const ModuleComponent = {
        savingscalculator: MonthSavingsCalculation,
        salarychange: SalaryTaxExchange,
        cookieConsentChangeButton: CookieConsentChangeButton,
    };
    const Component = ModuleComponent[module];

    return (
        <div>
            <Component />
        </div>
    );
};
