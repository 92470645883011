export const QuoteIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="15" viewBox="0 0 24 15" fill="none">
        <path
            d="M4.86903 15C2.25638 15 0 13.0899 0 10.5618C0 8.82022 0.712541 7.47191 1.78135 5.73034L5.34405 0H9.20365L6.94727 6.40449C8.37235 7.02247 9.6193 8.53933 9.6193 10.5056C9.6193 13.0899 7.36292 15 4.86903 15Z"
            fill="#D5004B"
        />
        <path
            d="M19.2497 15C16.6371 15 14.3807 13.0899 14.3807 10.5618C14.3807 8.82022 15.0932 7.47191 16.1621 5.73034L19.7248 0H23.5844L21.328 6.40449C22.7531 7.02247 24 8.53933 24 10.5056C24 13.0899 21.7436 15 19.2497 15Z"
            fill="#D5004B"
        />
    </svg>
);
