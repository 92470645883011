/** @type {import('tailwindcss').Config} */
module.exports = {
    theme: {
        fontFamily: {
            neutraface: ["var(--font-neutraface)"],
            "proxima-nova": ["var(--font-neutraface)"],
        },
        fontSize: {
            hero: [
                "3.5rem",
                {
                    lineHeight: "64px",
                    fontWeight: "700",
                },
            ],
            heroPhone: [
                "2.75rem",
                {
                    lineHeight: "56px",
                    fontWeight: "700",
                },
            ],
            h1: [
                "2.25rem",
                {
                    lineHeight: "44px",
                    fontWeight: "700",
                },
            ],
            h2: [
                "1.875rem",
                {
                    lineHeight: "36px",
                    fontWeight: "700",
                },
            ],
            h3: [
                "1.5rem",
                {
                    lineHeight: "29px",
                    fontWeight: "700",
                },
            ],
            h4: [
                "1.25rem",
                {
                    lineHeight: "24px",
                    fontWeight: "700",
                },
            ],
            h5: [
                "1rem",
                {
                    lineHeight: "22px",
                    fontWeight: "700",
                },
            ],
            large: [
                "1.125rem",
                {
                    lineHeight: "26px",
                },
            ],
            intro: [
                "1.125rem",
                {
                    lineHeight: "27px",
                    fontWeight: "400",
                },
            ],
            base: [
                "1rem",
                {
                    lineHeight: "22px",
                },
            ],
            small: [
                "0.875rem",
                {
                    lineHeight: "19px",
                    fontWeight: "400",
                },
            ],
            mini: [
                "0.75rem",
                {
                    lineHeight: "14px",
                    fontWeight: "400",
                },
            ],
            q: [
                "1.25rem",
                {
                    lineHeight: "28px",
                },
            ],
        },
        extend: {
            colors: {
                cherryRed: "#D5004B",
                cherryShadow: "#B05A79",
                cherryBloom: "#DD7F9B",
                cherryPetal: "#F2D2D9",
                cherryMist: "#F9EDF0",
                cherryDark: "#AC033E",

                lavender: "#7A7EA4",
                lavenderShadow: "#66698C",
                lavenderBloom: "#9397C2",
                lavenderBud: "#AFB2CE",
                lavenderPetal: "#CECEE0",
                lavenderMist: "#E4E4EF",
                lavenderCloud: "#F1F1F6",

                dandelion: "#FBBA00",
                dandelionDark: "#F3C951",
                dandelionMist: "#FFE7A4",
                dandelionLight: "#FFF7DE",

                lightBlack: "#3D3D3D",
                white: "#ffffff",
                brightGray: "#f8f8f8",
                lightGray: "#DDDDDD",
                ashGray: "#F2F2F2",
                gray: "#959595",

                indigo: "#5F6CCC",
                indigoDark: "#4C539B",
                indigoBright: "#EEF2FF",
            },
            boxShadow: {
                DEFAULT: "0px 1px 11px 0px rgba(0, 0, 0, 0.08)",
            },
        },
        container: {
            center: true,
        },
        animation: {
            "accordion-down": "accordion-down 0.2s ease-out",
            "accordion-up": "accordion-up 0.2s ease-out",
        },
        keyframes: {
            "accordion-down": {
                from: { height: "0" },
                to: { height: "var(--radix-accordion-content-height)" },
            },
            "accordion-up": {
                from: { height: "var(--radix-accordion-content-height)" },
                to: { height: "0" },
            },
        },
    },
    safelist: ["text-small", "text-large", "bg-[#F9EDF0], bg-[#F8F8F8], bg-[#F1F1F6]"],
    plugins: [
        function ({ addComponents }) {
            addComponents({
                ".container": {
                    maxWidth: "100%",
                    "@screen sm": {
                        maxWidth: "640px",
                    },
                    "@screen md": {
                        maxWidth: "768px",
                    },
                    "@screen lg": {
                        maxWidth: "1200px",
                    },
                    "@screen xl": {
                        maxWidth: "1200px",
                    },
                    "@screen 2xl": {
                        maxWidth: "1200px",
                    },
                },
            });
        },
        require("tailwindcss-animate"),
        require("@tailwindcss/container-queries"),
    ],
};
