const imagePath = `/static`;

// Tslint:disable
const icons = {
    BankId: `${imagePath}/images/authentication/bankid_vector_rgb.svg`,
    ChevronDownIcon: `${imagePath}/images/icons/chevronDown.svg`,
    logo: `${imagePath}/images/movestic-logo.svg`,
    logoFonder: `${imagePath}/images/movesticfonder-logo.svg`,
    backgroundLogo: `${imagePath}/images/m-logo-background.svg`,
    backgroundLogoWhite: `${imagePath}/images/m-logo-background-white.svg`,
    logoMinimal: `${imagePath}/images/movestic-logo-minimal.svg`,
    arrowLeft: `${imagePath}/images/icons/arrowLeft.svg`,
    arrowRight: `${imagePath}/images/icons/arrowRight.svg`,
    stopwatch: `${imagePath}/images/Orion_stopwatch.svg`,
    outpayment: {
        plan: `${imagePath}/images/outpayment/plan.svg`,
        lock: `${imagePath}/images/outpayment/lock.svg`,
        postpone: `${imagePath}/images/outpayment/postpone.svg`,
    },
};

const images = {
    upcomming: `${imagePath}/images/background-images/modal-image-left_tall.jpg`,
    heroImage: `${imagePath}/images/kvinna_haller_mobil.jpg`,
    maiaLogo: `${imagePath}/images/MAIA_Logo.svg`,
    info: `${imagePath}/images/information.png`,
    preferences: `${imagePath}/images/preferences.png`,
    offer: `${imagePath}/images/offer.png`,
    movesticLogo: `${imagePath}/images/movestic-logo.svg`,
    avanceraHeroImage: `${imagePath}/images/Avancera-hero.jpg`,
    demoImage: `${imagePath}/images/demo.png`,
    swesif: `${imagePath}/images/swesif-logo-16x16.gif`,
    lowCarbonDesignation: `${imagePath}/images/leaf.svg`,
    nordicEcoLabel: `${imagePath}/images/SE_Svanen_C_service_NEG_RGB.svg`,
    mobileLogin: `${imagePath}/images/mobile_login.png`,
    fullyWorking: `${imagePath}/images/fullyworking.png`,
    notFullyWorking: `${imagePath}/images/notfullyworking.png`,
    contatUsRound: `${imagePath}/images/contactusround.png`,
};

export { icons, images };
