function* graphValuesGenerator(startValue: number, inputFactor: number, addedValue: number): IterableIterator<number> {
    const addVal = addedValue / 12;
    let current = startValue;
    const calculationMethod = Math.pow(1 + inputFactor, 1 / 12) - 1;
    while (true) {
        for (let i = 1; i <= 12; i++) {
            const markup = (current + addVal) * calculationMethod;
            current = current + addVal + markup;
        }
        yield current;
    }
}

function yearsArray(maxYear: number) {
    const res = [];
    let i: number;
    for (i = 1; i <= maxYear; i++) {
        res.push(i);
    }

    return res;
}

export function calculateMonthSavingsGraphData(
    selectedYear: number,
    selectedPercent: number,
    selectedStartAmount: number,
    selectedMonthlySavingsAmount: number,
) {
    const factor: number = selectedPercent / 100;
    const yearlySavings: number = selectedMonthlySavingsAmount * 12;
    const startValue: number = selectedStartAmount * 1;
    const sequenceObj = graphValuesGenerator(startValue, factor, yearlySavings);
    const valuesArray = yearsArray(selectedYear).map(() => {
        const val = sequenceObj.next().value;
        return val;
    });
    return valuesArray;
}

export function calculateTotalSavings(monthlyAmount: number, yearlyInterestRate: number, yearsSaved: number): number {
    const res = calculateMonthSavingsGraphData(yearsSaved, yearlyInterestRate, 0, monthlyAmount);
    return res.at(-1);
}

export const calculateOutPaymentGraphData = (
    totalSavings: number,
    selectedPercent: number,
    selectedYear: number,
    selectedAmount: number,
    isTimeFixed: boolean,
    //outpaymentType: OutPaymentType,
) => {
    const factor: number = selectedPercent / 100;

    const valuePerMonth: number = isTimeFixed ? totalSavings / selectedYear / 12 : selectedAmount * 1;
    const calcultatedYear = totalSavings / (selectedAmount * 12);

    const sequenceObj = outpaymentGraphValuesGenerator(valuePerMonth, factor);
    const valuesArray = yearsArray(isTimeFixed ? selectedYear : calcultatedYear).map(() => {
        const val = sequenceObj.next().value;
        return val;
    });

    return valuesArray;
};

function* outpaymentGraphValuesGenerator(startValue: number, inputFactor: number): IterableIterator<number> {
    let factor: number = 0;
    let current = startValue;
    while (true) {
        const markup = startValue * factor;
        current = startValue + markup;
        factor = inputFactor;
        startValue = current;
        yield current;
    }
}
