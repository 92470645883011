import {
    TextblockComponent as TextblockProps,
    TextblocklistComponent as TextblockListProps,
} from "movestic-blocks/components/__generated__/graphql";
import { RichtextContent } from "movestic-blocks/tailwind/RichtextBlockRenderer";
import { icons } from "movestic-ui/images";
import SVG from "react-inlinesvg";

interface ExtendedTextblockProps extends TextblockProps {
    cherryDarkBackground?: boolean;
}

export const TextBlock: React.FunctionComponent<ExtendedTextblockProps> = ({
    text,
    color,
    stamp,
    cherryDarkBackground,
}) => {
    let appearance: string;
    let textColor = "text-lightBlack";

    if (color && color.includes("border")) {
        appearance = `border ${color}`;
        textColor = cherryDarkBackground ? "text-white" : textColor;
    } else {
        switch (color) {
            case "#F9EDF0":
                appearance = "bg-cherryMist";
                break;
            case "#F8F8F8":
                appearance = "bg-brightGray";
                break;
            case "#F1F1F6": //Legacy, but keeoping for compabilty. Remove ~ 2025Q2
                appearance = "bg-lavenderCloud";
                break;
            case "noColorNoShadow":
                appearance = "bg-white";
                break;
            default:
                appearance = "bg-white shadow";
                break;
        }
    }

    return (
        <div className={`relative rounded-lg p-6 ${appearance} ${textColor}`}>
            <RichtextContent richContent={text} />
            {stamp && (
                <div className="absolute right-0 z-10 -bottom-14 md:-bottom-12 md:-right-8 ">
                    <SVG src={icons.logoMinimal} />
                </div>
            )}
        </div>
    );
};

export const TextBlockList = ({ columns, textBlockItems }: TextblockListProps) => {
    let gridColumns: string;

    switch (columns) {
        case "3":
            gridColumns = "lg:grid-cols-3";
            break;
        case "4":
            gridColumns = "lg:grid-cols-4";
            break;
        default:
            gridColumns = "lg:grid-cols-2";
            break;
    }
    return (
        <div className={`grid w-full gap-6 ${gridColumns}`}>
            {textBlockItems?.map((textBlock: TextblockProps, index: number) => (
                <TextBlock key={index} {...textBlock} />
            ))}
        </div>
    );
};
