export const SadFace = ({
    size = "56",
    color = "#D5004B",
    style,
}: {
    size?: string;
    color?: string;
    style?: React.CSSProperties;
}) => (
    <svg width={size} height={size} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.4276 44.4273C40.0398 48.8146 34.206 51.2314 28.0005 51.2314C21.7951 51.2314 15.9613 48.815 11.5735 44.4273C7.18578 40.0393 4.7691 34.2055 4.7691 28C4.7691 21.7945 7.18559 15.9607 11.5735 11.573C15.9613 7.18529 21.7951 4.76861 28.0005 4.76861C34.206 4.76861 40.0398 7.18505 44.4276 11.573C48.8155 15.9607 51.2321 21.7945 51.2321 28C51.2321 34.2055 48.8157 40.0393 44.4276 44.4273ZM45.8199 10.1811C41.0598 5.42129 34.7319 2.80005 28.0005 2.80005C21.2692 2.80005 14.9411 5.42129 10.1816 10.1811C5.42171 14.9406 2.80048 21.2692 2.80048 28C2.80048 34.731 5.42171 41.0593 10.1816 45.819C14.9411 50.5786 21.2695 53.2 28.0005 53.2C34.7315 53.2 41.0598 50.5786 45.8199 45.819C50.5791 41.0593 53.2005 34.731 53.2005 28C53.2005 21.2692 50.5791 14.9406 45.8199 10.1811Z"
            fill={color}
        />
        <path
            d="M28.0392 31.7833C32.9994 31.7833 36.0015 33.7754 37.6463 35.4462C39.4478 37.2765 40.0555 39.1275 40.0806 39.2053C40.2466 39.7228 39.9614 40.2772 39.4437 40.4433C39.344 40.4753 39.2427 40.4905 39.143 40.4905C38.7266 40.4905 38.34 40.2244 38.2059 39.8066C38.2021 39.7953 37.6836 38.2618 36.1775 36.7615C34.1734 34.7645 31.4352 33.7523 28.0392 33.7523C24.6431 33.7523 21.9051 34.7645 19.9009 36.7615C18.3946 38.2618 17.8762 39.7953 17.8711 39.8107C17.7012 40.3252 17.1465 40.6076 16.6312 40.4398C16.1157 40.272 15.8324 39.7213 15.9977 39.2053C16.0228 39.1275 16.6304 37.2765 18.432 35.4462C20.0767 33.7754 23.0789 31.7833 28.0392 31.7833Z"
            fill={color}
        />
        <path
            d="M35.5834 25.6667C37.1942 25.6667 38.5 24.3609 38.5 22.75C38.5 21.1392 37.1942 19.8334 35.5834 19.8334C33.9725 19.8334 32.6667 21.1392 32.6667 22.75C32.6667 24.3609 33.9725 25.6667 35.5834 25.6667Z"
            fill={color}
        />
        <path
            d="M20.4167 25.6667C22.0275 25.6667 23.3333 24.3609 23.3333 22.75C23.3333 21.1392 22.0275 19.8334 20.4167 19.8334C18.8058 19.8334 17.5 21.1392 17.5 22.75C17.5 24.3609 18.8058 25.6667 20.4167 25.6667Z"
            fill={color}
        />
    </svg>
);
