export const LightbulbShine = ({
    size = "22",
    color = "#3D3D3D",
    style,
}: {
    size?: string;
    color?: string;
    style?: React.CSSProperties;
}) => (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.448 10.376C16.448 7.37598 14 4.92798 11 4.92798C8 4.92798 5.552 7.37598 5.552 10.376C5.552 10.808 5.59997 11.24 5.71997 11.672C5.86395 12.368 6.12793 12.8 6.48793 13.4C6.55996 13.5439 6.6559 13.6879 6.75191 13.856C6.87191 14.048 6.96793 14.24 7.08793 14.408C7.56793 15.2001 7.85589 15.656 7.85589 16.592V18.848C7.85589 19.424 8.26385 19.8801 8.81589 19.976C9.05589 21.2 9.77589 21.968 10.9999 21.968C12.2239 21.968 12.9678 21.2001 13.1839 19.976C13.7359 19.88 14.1439 19.4 14.1439 18.848V16.592C14.1439 15.656 14.4318 15.176 14.9118 14.408C15.0079 14.2401 15.1279 14.048 15.2479 13.856C15.3439 13.688 15.4399 13.5439 15.5118 13.4C15.8719 12.8 16.1358 12.3679 16.2798 11.672C16.4 11.24 16.4479 10.8079 16.4479 10.376H16.448ZM13.208 17.168H8.84V16.6159C8.84 16.4959 8.84 16.3999 8.81602 16.3039H13.208C13.208 16.3999 13.184 16.4959 13.184 16.6159L13.184 17.168H13.208ZM12.9921 19.064H9.00806C8.88806 19.064 8.81603 18.968 8.81603 18.872V18.128H13.184V18.872C13.208 18.968 13.1121 19.064 12.9921 19.064ZM11 21.032C10.736 21.032 10.064 21.032 9.80002 20.0241H12.224C11.96 21.032 11.264 21.032 11 21.032H11ZM15.344 11.48C15.224 12.008 15.032 12.368 14.6961 12.92C14.6 13.064 14.5281 13.208 14.4321 13.3761C14.3121 13.5681 14.2161 13.76 14.12 13.904C13.8321 14.384 13.568 14.8161 13.4 15.32H8.64804C8.48007 14.8161 8.24008 14.384 7.92804 13.904C7.83203 13.7361 7.71202 13.568 7.616 13.3761C7.51999 13.2081 7.42397 13.04 7.35202 12.92C7.016 12.344 6.82406 12.008 6.70406 11.4561C6.60805 11.0961 6.56008 10.7361 6.56008 10.3761C6.56008 7.90406 8.57608 5.88806 11.0481 5.88806C13.5201 5.88806 15.5361 7.90406 15.5361 10.3761C15.4881 10.7361 15.4401 11.0961 15.3441 11.48H15.344Z"
            fill={color}
        />
        <path
            d="M20.888 9.896H18.896C18.632 9.896 18.416 10.112 18.416 10.376C18.416 10.64 18.632 10.856 18.896 10.856H20.888C21.152 10.856 21.368 10.64 21.368 10.376C21.368 10.112 21.1521 9.896 20.888 9.896Z"
            fill={color}
        />
        <path
            d="M16.568 5.28794C16.688 5.28794 16.808 5.23997 16.904 5.14395L18.32 3.72793C18.5121 3.5359 18.5121 3.24793 18.32 3.05589C18.128 2.86385 17.84 2.86386 17.648 3.05589L16.232 4.47191C16.0399 4.66394 16.0399 4.95191 16.232 5.14395C16.328 5.23997 16.448 5.28794 16.568 5.28794Z"
            fill={color}
        />
        <path
            d="M11 2.95997C11.2639 2.95997 11.48 2.74395 11.48 2.47997V0.487935C11.48 0.223955 11.2639 0.00793457 11 0.00793457C10.736 0.00793457 10.52 0.223955 10.52 0.487935V2.47997C10.52 2.74395 10.736 2.95997 11 2.95997Z"
            fill={color}
        />
        <path
            d="M5.096 5.14395C5.19201 5.23997 5.31202 5.28793 5.43202 5.28793C5.55202 5.28793 5.67202 5.23997 5.76804 5.14395C5.96007 4.95192 5.96007 4.66395 5.76804 4.47191L4.35202 3.05589C4.15999 2.86386 3.87202 2.86386 3.67998 3.05589C3.48794 3.24792 3.48794 3.53589 3.67998 3.72793L5.096 5.14395Z"
            fill={color}
        />
        <path
            d="M3.12799 9.896H1.13594C0.871965 9.896 0.655945 10.112 0.655945 10.376C0.655945 10.64 0.871965 10.856 1.13594 10.856H3.12799C3.39196 10.856 3.60798 10.64 3.60798 10.376C3.60798 10.112 3.39205 9.896 3.12799 9.896Z"
            fill={color}
        />
        <path
            d="M5.09599 15.608L3.67997 17.024C3.48794 17.216 3.48794 17.504 3.67997 17.696C3.77599 17.792 3.89599 17.84 4.01599 17.84C4.13599 17.84 4.25599 17.792 4.35201 17.696L5.76803 16.28C5.96007 16.088 5.96007 15.8 5.76803 15.608C5.576 15.416 5.26397 15.416 5.09599 15.608Z"
            fill={color}
        />
        <path
            d="M16.904 15.608C16.712 15.416 16.424 15.416 16.232 15.608C16.0399 15.8 16.0399 16.088 16.232 16.2801L17.648 17.6961C17.744 17.7921 17.864 17.8401 17.984 17.8401C18.104 17.8401 18.224 17.7921 18.32 17.6961C18.5121 17.504 18.5121 17.2161 18.32 17.024L16.904 15.608Z"
            fill={color}
        />
    </svg>
);
