import { format, formatISO, isToday } from "date-fns";
import { sv } from "date-fns/locale";
import * as _ from "lodash";

import { toDate, ValidDateTypes } from "./dateHelpers";

const maxNumbersInOrgNr = 10;
const maxNumbersInSocialNr = 12;
const nonBreakingSpace = "\xa0";

const _moneyFormatter = (minimumFractionDigits: number, maximumFractionDigits?: number) =>
    new Intl.NumberFormat("sv-SE", {
        style: "currency",
        currency: "SEK",
        minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits ?? minimumFractionDigits,
    });

const _numberFormatter = (minimumFractionDigits: number, maximumFractionDigits?: number) =>
    new Intl.NumberFormat("sv-SE", {
        minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits ?? minimumFractionDigits,
    });

export const formatToPercent = (
    fraction: number,
    options?: {
        precision?: number;
        includeSign?: boolean;
        allowFalsy?: boolean;
        withPlusSign?: boolean;
    },
) => {
    const { precision = 0, includeSign = true, allowFalsy = false, withPlusSign = false } = options || {};

    if (!fraction && !allowFalsy) {
        return;
    }

    const sign = includeSign ? "% " : "";
    const formattedValue = (fraction * 100).toFixed(precision).replace(".", ",");
    const prefix = withPlusSign && fraction > 0 ? "+" : "";

    return `${prefix}${formattedValue}${sign}`;
};

export const formatOrgNr = (companyOrgNr: string) => {
    if (!companyOrgNr) {
        return;
    }

    if (companyOrgNr.length === maxNumbersInOrgNr) {
        return companyOrgNr.replace(/(\d{6})(\d{4})/, "$1-$2");
    } else if (companyOrgNr.length === maxNumbersInSocialNr) {
        return formatSocialNr(companyOrgNr);
    }

    return companyOrgNr.trim();
};

export const formatSocialNr = (socialNr: string) => {
    if (!socialNr) {
        return;
    }

    if (socialNr.length === maxNumbersInSocialNr) {
        return socialNr.replace(/(\d{8})(\d{4})/, "$1-$2");
    }

    return socialNr.trim();
};

export const formatPersonName = (person: { firstName?: string; name?: string }) => {
    if (!person) {
        return;
    }

    const name = `${(person.firstName || "").trim()} ${(person.name || "").trim()}`;

    return name;
};

export const formatPostCode = (postCode: string) => {
    if (!postCode) {
        return;
    }

    const code = postCode.replace(/(\d{3})(\d{2})/, "$1 $2");

    return code;
};

export enum DateFormat {
    Default,
    Fancy,
    FancyShort,
    WithTime,
    WithTimeShort,
    TimeStamp,
    FancyMonthAndYear,
    DaySlashMonthAndYear,
    ISOString,
    YYYYMMDD,
}

/**
 * The function to use for display of dates. If null or invalid this displays "-"
 * @param dateToFormat The date to format. Works with string in most variants, Date, timestamp and numbers
 * @param dateFormat Formatting of the date, defaults to __DateFormat.Default__
 *
 * __DateFormat.Default__ = 2020-01-01 (with "idag" for today)
 *
 * __DateFormat.Fancy__ = 1 januari 2020
 *
 * __DateFormat.FancyShort__ = 1 jan. 2020
 *
 * __DateFormat.WithTime__ = 1 januari kl. 14:18
 *
 * __DateFormat.WithTimeShort__ = 1 jan. 2020 14:18
 *
 * __DateFormat.TimeStamp__ = 2020-01-01 - 14:18:53
 *
 * __DateFormat.FancyMonthAndYear__ = januari 2020
 *
 * __DateFormat.DaySlashMonthAndYear__ = 21/3 2020
 *
 * __DateFormat.YYYYMMDD__ = 2020-01-01 (without "idag" for today)
 */
export const formatDate = (
    dateToFormat: ValidDateTypes,
    dateFormat: DateFormat = DateFormat.Default,
    showToday = true,
) => {
    if (!dateToFormat) {
        return dateFormat === DateFormat.YYYYMMDD ? "" : "-";
    }

    const date = toDate(dateToFormat);
    if (!date) {
        return "-";
    }

    if (
        isToday(date) &&
        dateFormat !== DateFormat.YYYYMMDD &&
        dateFormat !== DateFormat.DaySlashMonthAndYear &&
        showToday
    ) {
        return "idag";
    } else {
        switch (dateFormat) {
            case DateFormat.Default:
            case DateFormat.YYYYMMDD:
                return formatISO(date, { representation: "date" });
            case DateFormat.Fancy:
                return format(date, "d LLLL yyyy", { locale: sv });
            case DateFormat.FancyShort:
                return format(date, "d LLL yyyy", { locale: sv });
            case DateFormat.WithTime:
                return format(date, "d LLLL yyyy 'kl.' HH:mm", { locale: sv });
            case DateFormat.WithTimeShort:
                return format(date, "d LLL yyyy HH:mm", { locale: sv });
            case DateFormat.TimeStamp:
                return format(date, "yyyy-MM-dd - HH:mm:ss", { locale: sv });
            case DateFormat.FancyMonthAndYear:
                return format(date, "LLLL yyyy", { locale: sv });
            case DateFormat.DaySlashMonthAndYear:
                return format(date, "d/M yyyy", { locale: sv });
            default:
                break;
        }
    }
};

export const formatPersonOfficialIdToDate = (personOfficialId: string) =>
    formatDate(personOfficialId.substring(0, 8), DateFormat.YYYYMMDD);

export const moneyFormatter = (
    input: number,
    options?: { minimumFractionDigits?: number; maximumFractionDigits?: number },
) => {
    const { maximumFractionDigits = 2, minimumFractionDigits = maximumFractionDigits } = options || {};
    if (typeof input !== "number" || isNaN(input)) {
        return "-";
    }

    return _moneyFormatter(minimumFractionDigits, maximumFractionDigits).format(input);
};

export const getPersonFromRoles = (listofRoles: any[], roleType: string) => {
    const firstPersonInListWithRoleType = _.head(listofRoles.filter((r) => r.Type === roleType));
    const person = firstPersonInListWithRoleType ? firstPersonInListWithRoleType.person : null;

    return person;
};

export const formatNumber = (
    input: number,
    minimumFractionDigits: number = 0,
    maximumFractionDigits?: number,
): string => {
    if (typeof input !== "number" || isNaN(input)) {
        return "-";
    }

    return _numberFormatter(minimumFractionDigits, maximumFractionDigits).format(input);
};

export const formatWithSuffix = (
    input: number,
    suffix: string,
    minimumFractionDigits: number = 0,
    maximumFractionDigits?: number,
): string => {
    if (typeof input !== "number" || isNaN(input)) {
        return "-";
    }

    const formattedNumber = formatNumber(input, minimumFractionDigits, maximumFractionDigits);
    return `${formattedNumber}${nonBreakingSpace}${suffix}`;
};

export const calculateDiffPercent = (currentValue: number, originalValue: number): number =>
    (currentValue - originalValue) / originalValue;

export const enumFlagsToString = (flags: string[]): string => flags.join(", ");

export const shortMonetaryFormat = (value: number, options?: { includeKr: boolean }) => {
    const MILLION = 1_000_000;
    const THOUSAND = 1_000;
    let postfix = "";
    let dividedValue = value;
    const { includeKr = true } = options || {};
    if (isNaN(value) || value === null || value === undefined) {
        return "-";
    }

    if (value >= MILLION) {
        dividedValue = value / MILLION;
        postfix = "M";
    } else if (value >= THOUSAND) {
        dividedValue = value / THOUSAND;
        postfix = "k";
    }

    dividedValue = Math.round(dividedValue * 10) / 10;

    return `${formatNumber(dividedValue, 0, 1)}${nonBreakingSpace}${postfix}${includeKr ? "kr" : ""}`;
};

export const shortConvertedPercentFormat = (value: number) => {
    const postfix = "";
    let dividedValue = value;

    if (isNaN(value) || value === null || value === undefined) {
        return "-";
    }
    dividedValue = Math.round(dividedValue * 10) / 10;
    return `${formatNumber(dividedValue, 0, 1)}${nonBreakingSpace}${postfix}${" %"}`;
};

export const formatYearAndMonth = (input: number) => {
    const years = Math.floor(input);
    const months = Math.floor((input - years) * 12);
    return { years, months };
};
