import { ShortcutlistComponent as ShortcutListModel } from "movestic-blocks/components/__generated__/graphql";
import { ShortcutitemComponent as ShortcutItemModel } from "movestic-blocks/components/__generated__/graphql";
import { ShortcutModel } from "../ShortcutList";
import { H4, TextSmall } from "movestic-ui/tailwind/Texts";
import { getLink } from "../../../utils/cms/storyblokHelpers";
import { RichtextContent } from "../../RichtextBlockRenderer";
import Link from "next/link";

export const ShortcutListHigh = ({ props }: { props: ShortcutListModel }) => {
    const shortcuts = props.shortcuts as ShortcutItemModel[];
    const shortcutThreshold = parseInt(props?.columns || "4");
    const shortcutGrid = getGrid(shortcutThreshold);

    return (
        <div>
            {props.heading && <H4>{props.heading}</H4>}
            <div className={`grid grid-cols-1 ${shortcutGrid} gap-6 text-left text-lightBlack`}>
                {shortcuts?.map((item, index) => {
                    const lnk = getLink(item.link);

                    return (
                        <ShortcutHigh
                            linkUrl={lnk.linkUrl}
                            linkTarget={lnk.linkTarget}
                            text={item.text}
                            title={item.title}
                            icon={item.icon}
                            key={item.title + index}
                            label={item.label}
                        >
                            {item.extraContent && (
                                <RichtextContent
                                    richContent={item.extraContent}
                                    className="flex flex-col md:flex-row gap-4 mt-4 [&>div]:!mb-0"
                                />
                            )}
                        </ShortcutHigh>
                    );
                })}
            </div>
        </div>
    );
};

export function ShortcutHigh(shortcut: ShortcutModel) {
    const withExtraContent = !shortcut.linkUrl;
    const Elem = withExtraContent ? "div" : Link;
    return (
        <Elem
            className={`rounded-lg relative flex flex-col gap-2 p-6 no-underline bg-white shadow no-decoration ${!withExtraContent && "hover:bg-brightGray"}`}
            href={shortcut.linkUrl}
            target={shortcut.linkTarget}
        >
            {shortcut.icon?.filename && (
                <img
                    src={shortcut.icon?.filename}
                    alt={shortcut.icon?.alt ?? "Shortcut icon"}
                    style={{ width: "48px", margin: "0.5rem 0" }}
                />
            )}
            {shortcut.label && (
                <div className="absolute px-2 py-1 font-bold rounded right-4 bg-cherryMist text-cherryRed text-mini">
                    {shortcut.label}
                </div>
            )}
            {shortcut.title && <H4>{shortcut.title}</H4>}
            {shortcut.text && <TextSmall>{shortcut.text}</TextSmall>}
            {shortcut.children}
        </Elem>
    );
}

export function getGrid(columns: number) {
    switch (columns) {
        case 1:
            return "lg:grid-cols-1";
        case 2:
            return "lg:grid-cols-2";
        case 3:
            return "lg:grid-cols-3";
        default:
            return "lg:grid-cols-4";
    }
}
