import { NewsitemComponent } from "movestic-blocks/components/__generated__/graphql";
import { H4 } from "movestic-ui/tailwind/Texts";
import Link from "next/link";

export const NewsListItem = ({
    item,
    name,
    url,
    rootPage = "/",
}: {
    item: NewsitemComponent;
    name: string;
    url: string;
    rootPage?: string;
}) => {
    const style = {
        "--bg-small-url": `url('${item?.image?.filename}/m/420x0/smart/filters:quality(75)')`,
        "--bg-large-url": `url('${item?.image?.filename}/m/240x240/smart/filters:quality(75)')`,
    } as React.CSSProperties;

    return (
        <Link
            href={url}
            className={`no-underline flex rounded-lg overflow-hidden shadow flex-col md:flex-row hover:bg-brightGray`}
            key={item?.heading}
        >
            {item?.image?.filename && (
                <div
                    className={`flex bg-cover basis-2/5 bg-[image:var(--bg-small-url)] md:bg-[image:var(--bg-large-url)] min-h-52`}
                    style={style}
                ></div>
            )}
            <div className="p-6 basis-3/5 grow">
                <H4>{name}</H4>
                {item?.date && <div>{new Date(item.date).toLocaleDateString()}</div>}
                <div style={{ margin: "0.5rem 0" }}>{shortenString(item?.intro)}</div>
                {item?.category && (
                    <div
                        className={`border border-lightGray rounded inline bg-white text-lightBlack no-underline px-2 py-1 text-small`}
                    >
                        {item.category}
                    </div>
                )}
            </div>
        </Link>
    );
};

const shortenString = (str?: string | null | undefined) => (str ? str?.substring(0, 100).trim() + "..." : null);
